<template>
  <form @submit.prevent="saveContact" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Job Title</label>
      <input
        v-model="contact.job_title"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Job Title..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">First Name</label>
      <input
        v-model="contact.firstname"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="First Name..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Last Name</label>
      <input
        v-model="contact.lastname"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Last Name..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Email</label>
      <input
        v-model="contact.email"
        type="email"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Email..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Telephone</label>
      <input
        v-model="contact.telephone"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Telephone..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Mobile</label>
      <input
        v-model="contact.mobile"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Mobile..."
      />
    </div>

    <div class="w-full">
      <button
        type="button"
        @click="saveContact"
        class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save Contact</span>
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "AddEditContact",
  props: {
    contact: {
      required: true,
      type: Object
    }
  },
  methods: {
    saveContact: function() {
      this.$emit("complete", this.contact);
    }
  }
};
</script>